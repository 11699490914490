<template>
  <b-card
    no-body
    class="mb-1"
  >
    <b-card-header class="py-1">
      <h4 class="card-title font-weight-bolder">
        {{ $t('reports') }}
      </h4>
    </b-card-header>
    <div class="px-1">
      <b-row class="px-1 pb-1 pt-0">
        <b-col
          md="6"
          class="text-dark bg-blue-gradient border-info rounded flex-column p-1"
        >
          <h2 class="text-dark">
            {{ $t('resellTicket.report.total') }} :
            {{ report?.total }}
            {{ $t('resellTicket.report.totalID') }}
          </h2>
        </b-col>
        <b-col md="6">
          <div class="d-flex flex-column px-3 py-1">
            <span class="text-info h4">{{ $t('resellTicket.note.title') }}</span>
            <span class="text-dark">- {{ $t('resellTicket.note.note1') }}</span>
            <span class="text-danger">- {{ $t('resellTicket.note.note2') }}</span>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCol,
  BProgress,
  BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BProgress,
  },
  props: {
    report: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.$emit('fetch-report-resell-ticket')
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
